<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        :profile-data="profile"
        @accountUpdated="onAccountUpdated"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab v-if="profile.hasPassword">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- set password tab -->
    <b-tab v-if="!profile.hasPassword">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Set Password</span>
      </template>

      <account-setting-set-password
        @passwordSet="onPasswordSet"
      />
    </b-tab>
    <!--/ set password tab -->

    <!-- change email tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="AtSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Email</span>
      </template>

      <account-setting-email
        :current-email="profile.email || ''"
        @emailChanged="onEmailChanged"
      />
    </b-tab>
    <!--/ change email tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingSetPassword from './AccountSettingSetPassword.vue'
import AccountSettingEmail from './AccountSettingEmail.vue'

import ProfileService from '@/service/profile.service'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingSetPassword,
    AccountSettingEmail,
  },
  data() {
    return {
      profile: {},
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    onPasswordSet() {
      this.loadData()
    },
    onEmailChanged() {
      this.loadData()
    },
    onAccountUpdated() {
      this.loadData()
    },
    loadData() {
      ProfileService.getMyProfileAsync().then(result => {
        this.profile = result
      })
    },
  },
}
</script>
