<template>
  <b-card>

    <b-alert
      v-if="!profileDataLocal.emailConfirmed"
      show
      variant="warning"
      class="mb-2"
    >
      <div class="alert-body">
        Your email address has not been confirmed yet. <strong>Please confirm it.</strong>
      </div>
    </b-alert>

    <b-avatar
      v-b-modal.profile-image-upload-modal
      size="80"
      variant="light-primary"
      :src="userProfile.profileImageUrl"
    />

    <!-- upload button -->
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.profile-image-upload-modal
      variant="primary"
      size="sm"
      class="ml-2"
    >
      Change profile image
    </b-button>

    <ProfileImageUploadModal
      @imageUpdated="onProfileImageUpdated"
    />

    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >

      <!-- form -->
      <b-form
        class="mt-2"
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-row>

          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              name="firstname"
              :rules="{ max: 50 }"
            >
              <b-form-group
                label="Firstname"
                label-for="account-name"
              >
                <b-form-input
                  v-model="form.name"
                  placeholder="Firstname"
                  name="name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-name-live-feedback"
                />
                <b-form-invalid-feedback id="input-name-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              name="firstname"
              :rules="{ max: 50 }"
            >
              <b-form-group
                label="Surname"
                label-for="account-surname"
              >
                <b-form-input
                  v-model="form.surname"
                  name="surname"
                  placeholder="Surname"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-surname-live-feedback"
                />
                <b-form-invalid-feedback id="input-surname-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="profileDataLocal.email"
                name="email"
                :state="profileDataLocal.emailConfirmed"
                disabled
              />

              <b-button
                v-if="!profileDataLocal.emailConfirmed"
                class="float-right"
                size="sm"
                variant="primary"
                @click="verifyEmail"
              >
                Send verification email
              </b-button>

            </b-form-group>
          </b-col>

          <b-col>
            <validation-provider
              v-slot="validationContext"
              name="timezone"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Timezone"
                label-for="timezone-name"
              >
                <v-select
                  v-model="form.timeZone"
                  :options="timezoneOptions"
                  :reduce="timezone => timezone.value"
                  label="text"
                  name="timezone"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-timezone-live-feedback"
                />
                <b-form-invalid-feedback id="input-timezone-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <div class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetForm"
              >
                Reset
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BAvatar, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import {
  // eslint-disable-next-line no-unused-vars
  required, email, min, max, confirmed, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment-timezone'
import ProfileImageUploadModal from './ProfileImageUploadModal.vue'
import ProfileService from '@/service/profile.service'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BAlert,
    BCard,
    BAvatar,
    BFormInvalidFeedback,
    ProfileImageUploadModal,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileDataLocal: {},
      form: {
        name: '',
        surname: '',
        timeZone: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'auth/userProfile',
    }),
    timezoneOptions() {
      const result = [{
        value: null,
        text: 'Please select your timezone',
      }]

      const tzOptions = moment.tz
        .names()
        .map(name => {
          const now = Date.now()
          const zone = moment.tz.zone(name)
          return { name, offset: zone !== null ? zone.utcOffset(now) : 0 }
        })
        .sort((a, b) => (a.offset === b.offset
          ? a.name.localeCompare(b.name)
          : b.offset - a.offset))
        .map(zone => {
          const gmtOffset = `GMT${moment.tz(zone.name).format('Z')}`
          return {
            value: zone.name,
            text: `(${gmtOffset}) ${zone.name}`,
          }
        })

      result.push(...tzOptions)

      return result
    },
  },
  watch: {
    profileData() {
      this.profileDataLocal = JSON.parse(JSON.stringify(this.profileData))
      this.resetForm()
    },
  },
  created() {

  },
  methods: {
    resetForm() {
      this.form = {
        name: this.profileDataLocal.name,
        surname: this.profileDataLocal.surname,
        timeZone: this.profileDataLocal.timeZone,
      }

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    updateUserClaims() {
      this.$store.dispatch('auth/refreshUserProfile') // refresh profile
    },
    onProfileImageUpdated() {
      this.updateUserClaims()
    },
    onSubmit() {
      const self = this
      ProfileService.updateAsync(this.form).then(
        // eslint-disable-next-line no-unused-vars
        result => {
          self.$toast.success('Profile updated.', {
            icon: true,
          })

          self.updateUserClaims()
          self.$emit('accountUpdated')
        },
        err => {
          self.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        },
      )
    },
    verifyEmail() {
      const self = this
      ProfileService.requestEmailConfirmationAsync().then(() => {
        self.$swal({
          title: 'Confirm your email',
          text: 'We sent you a confirmation code to your email address.',
          icon: 'info',
          input: 'text',
          inputAttributes: {
            style: 'margin-top: 10px',
          },
          inputPlaceholder: 'Enter your verification code',
          confirmButtonText: 'Confirm',
          showLoaderOnConfirm: true,
          preConfirm: confirmationCode => ProfileService.confirmEmailAsync(confirmationCode)
            .catch(() => {
              self.$swal.showValidationMessage(
                'Failed to validate confirmation code.',
              )
            }),
          allowOutsideClick: () => !self.$swal.isLoading(),
        }).then(clickResult => {
          if (clickResult.isConfirmed) {
            self.$swal.fire({
              title: 'Email confirmed',
              text: 'You successfully confirmed your email',
              icon: 'success',
            })

            self.profileDataLocal.emailConfirmed = true
            self.updateUserClaims()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
