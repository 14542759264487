<template>
  <b-card>

    <p>Your account is managed by an external provider (e.g. Office 365). You can set a password to your ONEInsight account to login independently from your external provider.</p>

    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <!-- form -->
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              name="newPassword"
              :rules="{ required: true, max: 128, min: 6, confirmed:'confirmPassword' }"
            >
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="form.newPassword"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-new-password-live-feedback"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                  <b-form-invalid-feedback id="input-new-password-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              name="confirmPassword"
              :rules="{ required: true, min: 6, max: 128 }"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="form.confirmPassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-confirm-password-live-feedback"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                  <b-form-invalid-feedback id="input-confirm-password-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, min, max, confirmed, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProfileService from '@/service/profile.service'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      form: {
        newPassword: '',
        confirmPassword: '',
      },
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetForm() {
      this.form = {
        newPassword: '',
        confirmPassword: '',
      }

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      const self = this
      ProfileService.updatePasswordAsync(this.form.currentPassword, this.form.confirmPassword).then(
        // eslint-disable-next-line no-unused-vars
        result => {
          self.$toast.success('Password set.', {
            icon: true,
          })

          self.resetForm()

          self.$emit('passwordSet')
        },
        err => {
          self.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        },
      )
    },
  },
}
</script>
