<template>
  <div>
    <b-modal
      id="profile-image-upload-modal"
      title="Upload profile image"
      :visible="show"
      no-close-on-backdrop
      @show="resetModal"
      @ok="upload"
    >
      <cropper
        ref="cropper"
        class="cropper"
        :src="image.src"
        :stencil-props="{ aspectRatio: 1/1 }"
        stencil-component="circle-stencil"
      />

      <b-form-file
        v-if="!image.src"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept=".jpg, .png"
        @change="loadImage($event)"
      />

      <p
        v-if="!image.src"
        class="mt-2"
      >
        Allowed JPG or PNG. Max size of 2Mb.
      </p>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          @click="deleteProfileImage"
        >
          Delete
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          OK
        </b-button>
        <b-button
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>

  </div>
</template>

<script>
import { BButton, BModal, BFormFile } from 'bootstrap-vue'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import ProfileService from '@/service/profile.service'

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i += 1) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    case '47494638':
      return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

export default {
  components: {
    BButton,
    BModal,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
    BFormFile,
  },
  data() {
    return {
      image: {
        src: null,
        type: null,
      },
      show: false,
    }
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },
  methods: {
    resetModal() {
      this.image = {
        src: null,
        type: null,
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      // eslint-disable-next-line no-unused-vars
      canvas.toBlob(blob => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type)
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      }
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          }
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0])
      }
    },
    upload() {
      const self = this
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        canvas.toBlob(blob => {
          ProfileService.updateProfileImage(blob).then(
            // eslint-disable-next-line no-unused-vars
            result => {
              self.$emit('imageUpdated')

              self.$toast.success('Image updated.', {
                icon: true,
              })

              self.show = false
            },
            err => {
              self.$swal({
                title: 'Something went wrong!',
                text: err.response.data.error.message,
                icon: 'error',
              })
            },
          )
        }, 'image/jpeg')
      }
    },
    deleteProfileImage() {
      const self = this
      ProfileService.deleteProfileImage().then(
        () => {
          self.$emit('imageUpdated')

          self.$toast.success('Image deleted.', {
            icon: true,
          })

          self.show = false
        },
        err => {
          self.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        },
      )
    },
  },
}
</script>
