<template>
  <b-card>

    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <!-- form -->
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row>
          <!-- old password -->
          <b-col md="12">

            <b-form-group
              label="Current Email"
              label-for="account-current-email"
            >
              <b-form-input
                id="account-current-email"
                v-model="currentEmail"
                name="current-email"
                disabled
              />
            </b-form-group>

          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new email -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              name="newPassword"
              :rules="{ required: true, email: true, confirmed:'confirmEmail' }"
            >
              <b-form-group
                label-for="account-new-email"
                label="New Email"
              >
                <b-form-input
                  id="account-new-email"
                  v-model="form.newEmail"
                  name="new-email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback id="input-confirm-password-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new email -->

          <!-- retype email -->
          <b-col md="6">
            <validation-provider
              v-slot="validationContext"
              name="confirmEmail"
              :rules="{ required: true, email: true }"
            >
              <b-form-group
                label-for="account-retype-new-email"
                label="Retype New Email"
              >
                <b-form-input
                  id="account-retype-new-email"
                  v-model="form.confirmEmail"
                  name="retype-email"
                  placeholder="New Email"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback id="input-confirm-password-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype email -->

          <b-col cols="6">
            <validation-provider
              v-slot="validationContext"
              name="confirmPassword"
              :rules="{ required: true, max: 128 }"
            >
              <b-form-group
                label-for="account-confirm-password"
                label="Confirm your password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-confirm-password"
                    v-model="form.passwordConfirmation"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Your password"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-confirm-password-live-feedback"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                  <b-form-invalid-feedback id="input-confirm-password-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, min, max, confirmed, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProfileService from '@/service/profile.service'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    currentEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      passwordFieldTypeRetype: 'password',
      form: {
        newEmail: '',
        confirmEmail: '',
        passwordConfirmation: '',
      },
    }
  },
  computed: {
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetForm() {
      this.form = {
        newEmail: '',
        confirmEmail: '',
        passwordConfirmation: '',
      }

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      const self = this
      ProfileService.requestEmailChangeAsync(this.form.passwordConfirmation, this.form.newEmail).then(
        // eslint-disable-next-line no-unused-vars
        result => {
          self.$swal({
            title: 'Verify your email',
            text: 'We sent you a confirmation code to your new email address.',
            icon: 'info',
            input: 'text',
            inputAttributes: {
              style: 'margin-top: 10px',
            },
            inputPlaceholder: 'Enter your verification code',
            confirmButtonText: 'Confirm',
            showLoaderOnConfirm: true,
            preConfirm: confirmationCode => ProfileService.confirmEmailChangeAsync(confirmationCode)
              .catch(() => {
                self.$swal.showValidationMessage(
                  'Failed to validate confirmation code.',
                )
              }),
            allowOutsideClick: () => !self.$swal.isLoading(),
          }).then(clickResult => {
            if (clickResult.isConfirmed) {
              self.$swal.fire({
                title: 'Email changed',
                text: 'You successfully changed your email',
                icon: 'success',
              })

              self.$emit('emailChanged')
            }
          })

          self.resetForm()
        },
        err => {
          self.$swal({
            title: 'Something went wrong!',
            text: err.response.data.error.message,
            icon: 'error',
          })
        },
      )
    },
  },
}
</script>
